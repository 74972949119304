import { include } from 'named-urls';

export default {
    notfound: '/404-not-found',
    error: '/500-error',

    auth: include('/auth', {
        login: 'l/:path',
        forgotpassword: 'fp/:path',
        resetpassword: 'rp/:path',
        register: 'r/:path',
        confirm: 'c/:path'
    }),

    e: '/e/:type/:path',

    group: include('/g', {
        overview: ':path/:date?',
        optimal: 'o/:path/:date?',
        decisionTree: 'd/:path'
    }),

    agenda: include('/a', {
        overview: ':path/:date?',
        move: 'm/:path/:date?'
    }),

    type: include('/t', {
        overview: ':path',
        agenda: 'a/:path/:date?',
        group: 'g/:path/:date?',
        group_merged: 'gm/:path/:date?',
        move: 'm/:path/:date?',
        list: 'l/:path',
        arrangement: 'ar/:path'
    }),

    wellness: include('/w', {
        overview: '',
        calendar: include('c', {
            agenda: 'a/:path',
            group: 'g/:path'
        })
    }),

    lastminute: include('/lm', {
        overview: ':path',
        persons: 'p/:path'
    }),

    appointment: include('/app', {
        overview: '',
        book: 'b/:path',
        agenda: 'al/:path',
        confirmation: 'c/:path',
        edit: 'e/:path',
        manage: 'm/:path',
        cancel: 'ca/:path'
    }),

    payment: include('/payment', {
        expired: 'expired/:transactionId?',
        paid: 'paid/:transactionId?',
        error: 'error/:transactionId?'
    })

    /*contact: include('/p', {
        overview: '',
        edit: 'e/:path',
        appointments: 'a'
    })*/
}